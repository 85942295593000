import React from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import DeveloperCard from './DeveloperCard';

const DeveloperBox = ({ developers }) => {
    // Filter developers who have logos
    const developersWithLogos = developers.filter(developer => developer.logo);

    // Settings for the Slick carousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className='prev-arrow' />,
        nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className='next-arrow' />,
        dots: false,
        autoplay: true,
        arrows: false,
        autoplaySpeed: 0,
        speed: 3000,
        cssEase: 'linear',
        infinite: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        responsive: [
            {
                breakpoint: 1441,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: false
                }
            },
            {
                breakpoint: 993,
                settings: {
                    dots: false,
                    arrows: false,
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    dots: false,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    // Check if there are more than 4 developers
    const shouldShowCarousel = developersWithLogos.length > 4;

    return (
        <div className="developer-box-container">
            {shouldShowCarousel ? (
                <Slider {...settings}>
                    {developersWithLogos.map((developer) => (
                        <div>
                            <DeveloperCard key={developer.id} developer={developer} />
                        </div>
                    ))}
                </Slider>
            ) : (
                <div className="developer-box-grid">
                    {developersWithLogos.map((developer) => (
                        <DeveloperCard key={developer.id} developer={developer} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default DeveloperBox;
