import React from 'react';
import { s3url } from './../../Admin/Utils';

const ProjectReraQrImage = ({ reraImageArray, reraId, projectName }) => {

    return(
        // console.log(reraImageArray)

        <div className='rera-qr-wrapper'> 
        {
            reraImageArray.map((item, index) => (
                <div key={index} className='rera-qr-image'>
                    <img src={`${s3url}/project-assets${item.url}`} alt={item.alt} />
                    <p><b>{reraId}</b></p>
                    <p>{projectName}</p>
                    <p><a href='https://maharera.maharashtra.gov.in/'>https://maharera.maharashtra.gov.in/</a></p>
                </div>
                
            ))
        }
        </div>
           
    );

}

export default ProjectReraQrImage;