import React, { useState, useRef, useContext, useEffect } from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';
import { userToken, executeLaravelAPI, showToastMessage, userSrd, createGa4Event,executeLaravelFrontAPI } from '../../Admin/Utils';
import { SearchVisibilityContext } from '../Layouts/SearchVisibilityContext';
import { CompletePurchase } from '../../Admin/GoogleAnalytics';


const StudioForm = ({ projectId, developerId, projectName }) => {
    const formRef = useRef(null);
    const [errorMsg, setErrors] = useState({});

    const { setPriceFormSubmitted } = useContext(SearchVisibilityContext);
    const initialFormState = {
        enquiry_name: '',
        enquiry_company: '',
        enquiry_email: '', // Added email field
        enquiry_phone: '',
        agreetnc: false
    };
    const [formData, setFormData] = useState(initialFormState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    useEffect(() => {
        setPriceFormSubmitted(false);
    }, [projectId, setPriceFormSubmitted]);

    const handleUserAddSubmit = async (e) => {
        try {
            const { enquiry_name, enquiry_company, enquiry_email, enquiry_phone, agreetnc } = formData;

            const messages = [];

            if (!enquiry_name.trim()) {
                messages.enquiry_name = ['Name is required'];
            } else if (!/^[a-z A-Z\s]+$/.test(enquiry_name)) {
                messages.enquiry_name = ['Name should only contain alphabetic characters'];
            }

            if (!enquiry_company.trim()) {
                messages.enquiry_company = ['Company name is required'];
            }

            if (!enquiry_email.trim()) {
                messages.enquiry_email = ['Email is required'];
            } else if (!/^\S+@\S+\.\S+$/.test(enquiry_email)) {
                messages.enquiry_email = ['Invalid email address'];
            }

            if (!enquiry_phone.trim()) {
                messages.enquiry_phone = ['Phone no is required'];
            } else if (enquiry_phone.length < 10) {
                messages.enquiry_phone = ['Phone no must be at least 10 characters long'];
            } else if (!/^[6-9]\d{9}$/.test(enquiry_phone)) {
                messages.enquiry_phone = ['Invalid phone number format'];
            }

            if (!agreetnc) {
                messages.agreetnc = 'You must agree to the terms and conditions';
            }

            if (Object.keys(messages).length > 0) {
                setErrors(messages);
                return;
            }

            const result = await executeLaravelFrontAPI('addbwstudiolead', formData, 'POST');
            if (result.status === 200) {
                showToastMessage('Your request has been submitted successfully.', 'top-right', 'success');
                createGa4Event('selldo_form_submitted', 'Selldo Form Submitted Detail page', `${formData.enquiry_phone}`);
                setFormData(initialFormState);
                formRef.current.reset();
            } else {
                setErrors(result.message);
                showToastMessage('Failed to submit request', 'top-right', 'error');
            }
        } catch (error) {
            console.error('Error:', error);
            showToastMessage('Failed to submit request', 'top-right', 'error');
        }
    };

    
    return (
        <div className="left-form bg-white d-flex flex-column gap-4 realestate-form">
            <div className="d-flex align-items-center gap-2 justify-content-center form-heading">
                <span className="text-dark fs-4 fw-bold priceTitle">Get a call back from our expert</span>
            </div>

            <form id="enquiry-form-pdp" className="enquiry-form" ref={formRef}>
                <div className="d-flex flex-column gap-3">
                    <Form.Group className="row mb-2">
                        <Form.Control type="hidden" name='project_id' id='enquiry_project_id' />
                        <Form.Control type="hidden" name='developer_id' id='enquiry_developer_id' />
                        <div className="col-sm-12 position-relative">
                            <FloatingLabel controlId="floatingName1" label="Name">
                                <Form.Control placeholder="Name" type="text" name='enquiry_name' onChange={handleChange} />
                            </FloatingLabel>
                            {errorMsg.enquiry_name && <div className="text-danger error-msg">{errorMsg.enquiry_name[0]}</div>}
                        </div>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                        <div className="col-sm-12 position-relative">
                            <FloatingLabel controlId="floatingCompany1" label="Company Name">
                                <Form.Control placeholder="Company Name" type="text" name='enquiry_company' onChange={handleChange} />
                            </FloatingLabel>
                            {errorMsg.enquiry_company && <div className="text-danger error-msg">{errorMsg.enquiry_company[0]}</div>}
                        </div>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                        <div className="col-sm-12 position-relative">
                            <FloatingLabel controlId="floatingEmail1" label="Email">
                                <Form.Control placeholder="Email" type="text" name='enquiry_email' onChange={handleChange} />
                            </FloatingLabel>
                            {errorMsg.enquiry_email && <div className="text-danger error-msg">{errorMsg.enquiry_email[0]}</div>}
                        </div>
                    </Form.Group>
                    <Form.Group className="row mb-2">
                        <div className="col-sm-12 position-relative">
                            <FloatingLabel controlId="floatingPhone1" label="Phone Number">
                                <Form.Control placeholder="Phone Number" type="text" name='enquiry_phone' maxLength="10" onChange={handleChange} />
                            </FloatingLabel>
                            {errorMsg.enquiry_phone && <div className="text-danger error-msg">{errorMsg.enquiry_phone[0]}</div>}
                        </div>
                    </Form.Group>
                    <div className="form-check c-pointer position-relative">
                        <input className="form-check-input agreetnc" name="agreetnc" type="checkbox" id="flexCheckChecked" checked={formData.agreetnc} onChange={handleChange} />
                        <label className="form-check-label fs-6" htmlFor="flexCheckChecked">
                            I agree to the <a href="https://corporate.beyondwalls.com/terms-and-conditions" ><span className="text-primary text-decoration-underline">terms and conditions</span></a>
                        </label>
                        {errorMsg.agreetnc && <div className="text-danger error-msg">{errorMsg.agreetnc}</div>}
                    </div>
                    <a onClick={() => [handleUserAddSubmit(), createGa4Event("project_detail_page_enquiry", 'Project Enquiry Form', `${projectName}`)]} id="register" className="btn sc register-btn register-btnstudio text-center">
                        <span className="d-block signin text-uppercase">ENQUIRE NOW</span>
                    </a>
                </div>
            </form>
        </div>
    );
};

export default StudioForm;

