import React, { useState, useEffect } from "react";
import { Icon } from '@iconify/react';
import Slider from 'rc-slider';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { formatNumber, createGa4Event, appAbsPath } from './../../Admin/Utils';
import { setProjectMaxBudgetFilter } from './../../Admin/UserActivatyTracker';

const MobileFilter = ({
    selectedStatus,
    toggleStatus,
    selectedPossessionStatus,
    togglePossessionStatus,
    selectedBHK,
    toggleBhkType,
    priceRange,
    tempPriceRange,
    builtUpArea,
    tempBuiltUpArea,
    filteredByBhk,
    showAllBhk,
    initialDisplayLimit,
    sortProjectsByBhkType,
    setSelectedTab,
    handleSliderChange,
    handleSliderChangeCommitted,
    handleSliderChangeArea,
    handleSliderChangeCommittedArea,
    sortCriteria,
    setSortCriteria,
    clearUrlParams,
    hasParams
}) => {
    let location = useLocation();
    const [visible, setVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [isPdp, setPdp] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > lastScrollY) {
            // Scrolling down
            setVisible(false);
        } else {
            // Scrolling up
            setVisible(true);
        }
        setLastScrollY(window.scrollY);
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);
    useEffect(() => {
        if (window.location.pathname.includes('irfs')) {
            setPdp(true);
        }
    }, []);


    const handleShowFilterSidebarBtn = () => {
        const rightSideDiv = document.querySelector('.filter-sidebar');
        rightSideDiv.classList.toggle('active');
    }
    const handleHideFilterSidebarBtn = () => {
        handleShowFilterSidebarBtn();
    }

    const handleShowSortSidebarBtn = () => {
        const sortFilter = document.getElementById('sort-filter');
        sortFilter.classList.add('active');
    }

    const handleHideSortSidebarBtn = () => {
        const sortFilter = document.getElementById('sort-filter');
        sortFilter.classList.remove('active');
    }

    const combinedOnChange = (newRange) => {
        handleSliderChange(newRange);
    };

    const combinedOnChangeComplete = (newRange) => {
        handleSliderChangeCommitted(newRange);
        setProjectMaxBudgetFilter(newRange[1]);
        createGa4Event('filter_budget', 'BudgetFilter', `${newRange[0]}-${newRange[1]}`);
    };

    const combinedOnChangeArea = (newRangeArea) => {
        handleSliderChangeArea(newRangeArea);
    };

    const combinedOnChangeCompleteArea = (newRangeArea) => {
        handleSliderChangeCommittedArea(newRangeArea);
        createGa4Event('filter_builtup', 'Built Up Filter', `${newRangeArea[0]}-${newRangeArea[1]}`);
    };

    return (
        <>
            {/* Mobile Filters */}
            <div className='mobile-map-list-view visible-ipro'>

                <a href={`${appAbsPath}${isPdp ? '/irfs/projects' : '/projects'}${location.search}`} className={` ms-2 text-decoration-underline border p-1  rounded-pill`} onClick={() => createGa4Event('On Click', 'List View', 'List View')}>
                    <Icon icon="uim:list-ul" style={{ "color": "rgb(107, 107, 107)", "width": "40px", "height": "25px" }} />
                </a>
                <a className={`text-primary ms-2 text-decoration-underline border-primary p-1 rounded-pill`} onClick={() => createGa4Event('On Click', 'Map View', 'Map View')}>
                    <Icon icon="uiw:map" style={{ "color": "", "width": "40px", "height": "25px" }} />
                </a>
            </div>
            <div className={`visible-ipro mobile-filter-wrap ${visible ? 'slide-up' : 'slide-down'}`}>
                <a className='filter-sidebar-sort' onClick={handleShowSortSidebarBtn}><Icon icon="bx:sort" /> Sort</a>
                <a className='filter-sidebar-btn' onClick={handleShowFilterSidebarBtn}><Icon icon="mage:filter" /> Filter</a>
            </div>
            <div className='visible-ipro mobile-filter-wrap-popup' id="sort-filter">
                <div className='filter-sidebar-sort-popup'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex gap-3 align-items-center'>
                            <a className='' onClick={handleHideSortSidebarBtn}>
                                <svg width="19" height="16" viewBox="0 0 19 16" xmlns="http://www.w3.org/2000/svg"><path d="M17.556 7.847H1M7.45 1L1 7.877l6.45 6.817" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"></path></svg>
                            </a>
                            <h4 className='mb-0'>Sort By:</h4>
                        </div>
                        {hasParams && (
                            <button
                                className='btn btn-primary sc py-1 h-auto'
                                onClick={() => clearUrlParams(true)}
                            >
                                Clear All
                            </button>
                        )}
                    </div>
                    <hr />
                    <ul>
                        {['relevance', 'recentlyLaunched', 'priceLowToHigh', 'priceHighToLow', 'sqftLowToHigh', 'sqftHighToLow', 'possessionDate'].map((criteria) => (
                            <li
                                key={criteria}
                                onClick={() => {
                                    setSortCriteria(criteria);
                                    handleHideSortSidebarBtn();
                                    createGa4Event('On Click', 'Filters', criteria);
                                }}
                                className={sortCriteria === criteria ? 'active' : ''}
                            >
                                <div className='d-flex justify-content-between'>
                                    {criteria.charAt(0).toUpperCase() + criteria.slice(1).replace(/([A-Z])/g, ' $1').trim()}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className='filter-sidebar visible-ipro'>
                <div className="headBtns">
                    <a className='' onClick={handleHideFilterSidebarBtn}>
                        <svg width="19" height="16" viewBox="0 0 19 16" xmlns="http://www.w3.org/2000/svg"><path d="M17.556 7.847H1M7.45 1L1 7.877l6.45 6.817" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"></path></svg>
                    </a>
                    <h4 className='mb-0'>Filters:</h4>
                    {hasParams && (
                        <button
                            className='btn btn-primary sc py-1 h-auto'
                            onClick={() => clearUrlParams(true)}
                        >
                            Clear All
                        </button>
                    )}
                </div>
                <hr className='mt-0' />
                <Tab.Container id="left-tabs-example" defaultActiveKey="three">
                    <Row className='align-items-stretch d-flex'>
                        <Col sm={4} className='pe-0 align-items-stretch d-flex flex-column left-tab-container'>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="three">Project Status</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className='flex-column d-flex'>
                                    <Nav.Link eventKey="first">Configuration Type</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="five">Possession Status</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Budget</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="four">Carpet Area (Sq.Ft.)</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={8} className='ps-3'>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    {filteredByBhk && Array.isArray(filteredByBhk) && selectedBHK && Array.isArray(selectedBHK) && filteredByBhk.length > 0 && filteredByBhk.slice(0, showAllBhk ? filteredByBhk.length : initialDisplayLimit).map((BhkType, index) => (
                                        BhkType !== "" && (
                                            BhkType && BhkType.bedrooms && BhkType.type &&
                                            <a key={index} className={`btn btn-outline-secondary me-2 mb-2 ${selectedBHK.includes(BhkType.bedrooms + " " + BhkType.type.toUpperCase()) ? 'active' : ''}`}
                                                onClick={() => {
                                                    toggleBhkType(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                                                    sortProjectsByBhkType(selectedBHK);
                                                    setSelectedTab(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                                                    createGa4Event('filter_configuration_type', 'ConfigurationTypeFilter', BhkType.bedrooms + " " + BhkType.type.toUpperCase())
                                                }}>
                                                {BhkType.bedrooms} {BhkType.type.toUpperCase()}
                                            </a>
                                        )
                                    ))}
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <Slider
                                        range
                                        min={500000}
                                        max={50000000}
                                        value={tempPriceRange}
                                        onChange={combinedOnChange}
                                        onChangeComplete={combinedOnChangeComplete}
                                        step={500000}
                                    />
                                    <p className='d-flex justify-content-between'>
                                        <span>{`₹${formatNumber(tempPriceRange[0])}`}</span>
                                        <span className='pe-md-0 pe-3'>{`₹${formatNumber(tempPriceRange[1])}`}{tempPriceRange[1] === 50000000 && '+'}</span>
                                    </p>
                                </Tab.Pane>
                                <Tab.Pane eventKey="three">
                                    {["New Project", "Under Construction", "Ready To Move", "New Launch", "Nearing Possession"].map((status) => (
                                        <a key={status} className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes(status) ? 'active' : ''}`}
                                            onClick={() => toggleStatus(status)}>
                                            {status}
                                        </a>
                                    ))}
                                </Tab.Pane>
                                <Tab.Pane eventKey="five">
                                    {[3, 6, 12, 24, 36, 60, 84].map((months) => (
                                        <a key={months} className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == months ? 'active' : ''}`}
                                            onClick={() => {
                                                togglePossessionStatus(months.toString());
                                                createGa4Event('filter_possession_status', 'PossessionStatusFilter', `In ${months <= 12 ? months + ' months' : Math.floor(months / 12) + ' years'}`)
                                            }}>
                                            In {months <= 12 ? months + ' months' : Math.floor(months / 12) + ' years'}
                                        </a>
                                    ))}
                                </Tab.Pane>
                                <Tab.Pane eventKey="four">
                                    <Slider
                                        range
                                        min={100}
                                        max={5000}
                                        value={tempBuiltUpArea}
                                        onChange={combinedOnChangeArea}
                                        onChangeComplete={combinedOnChangeCompleteArea}
                                        step={15}
                                    />
                                    <p className='d-flex justify-content-between'>
                                        <span>{`${tempBuiltUpArea[0]} Sq.Ft.`}</span>
                                        <span className='pe-md-0 pe-3'>{`${tempBuiltUpArea[1]} Sq.Ft.`} {tempBuiltUpArea[1] === 5000 && '+'}</span>
                                    </p>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                <button className="btn btn-primary w-100 theme-btn filter-apply-btn" onClick={handleHideFilterSidebarBtn}>Apply</button>
            </div>
        </>
    );
};

export default MobileFilter;

