import React, { useEffect, useState, lazy,  useContext } from 'react';
import { OverlayTrigger, Tooltip, Carousel } from 'react-bootstrap'; // Ensure you have 'react-bootstrap' installed
import { Link } from 'react-router-dom'; // Ensure you're using 'react-router-dom' for navigation
// import Loader from '../../Loader';
import defaultGallery from '../../../images/default-corporate-image.webp';
// import lazyimg from '../../../images/beyondwalls.png';
// import Discount from '../../../Assets/img/discount.svg';
import { lazyLoadImages, s3url, formatNumber, parsedUserData, showToastMessage, numberWithCommas, createGa4Event, isMobileDevice, convertShortDate, dateDiffInMonths, checkFileExtension } from '../../Admin/Utils';
import { Icon } from '@iconify/react';
import WishListBtn from './WishListBtn';
import EMICalculator from './EMICalculator';
import { CompareProjectContext } from '../Layouts/CompareProjectContext';
import ShareButtonWithPopup from './ShareButtonWithPopup';
import CouponButtons from '../Layouts/CouponButtons';
import OfferTag from '../Layouts/OfferTag';
import 'core-js';
import Gamification from '../Layouts/Gamification';

// import $ from 'jquery';

const Loader = lazy(() => import('../../Loader'));
// const emiCalRef = useRef(null);

// const [showResult, showSearchResult] = useState(false);
// const handleEMIClick = () => {
//     showSearchResult(true);
// };
const isMobile = isMobileDevice();

const ConfigurationDetails = ({ project, configuration, minCarpet, maxCarpet, minPrice, maxPrice, quoted_per_sqft_price }) => {
    const [emiPopup, setEmiPopup] = useState(false);
    const { isProjectId, setProjectId } = useContext(CompareProjectContext);
    const { isDeveloperId, setDeveloperId } = useContext(CompareProjectContext);

    const handleEMIClick = () => {
        setEmiPopup(!emiPopup);
    };
    const handleCloseEMIPopup = () => {
        setEmiPopup(null);
    };
    const handleClickEnquiryForm = () => {
        setProjectId(project.id);
        setDeveloperId(project.developer.id);
        document.getElementById('overlayEnquiry').style.display = 'block';
        document.getElementById('enquiry_triggered_from').value = 'Price On Request - Project listing';
    }

    // console.log("User Data: ",parsedUserData);

    return (
        <>
            <div className="mt-2 d-none d-sm-block">
                <p className="text-mob-comparision plp-carpet-area">Carpet area: <span
                    className="text-listing fw-bold">
                    {minCarpet.toFixed(0) === maxCarpet.toFixed(0) ?
                        numberWithCommas(minCarpet.toFixed(0))
                        :
                        numberWithCommas(minCarpet.toFixed(0)) + " - " + numberWithCommas(maxCarpet.toFixed(0))
                    } sq.ft.</span>
                </p>
            </div>
            <div className={`tab-pane fade show active`}>
                <div className="plp-price-cards mt-1 parent">
                    <div className="card border-primary text-dark d-none d-sm-block">
                        <div className="card-header p-2 pt-1 pb-1 fw-bold border-bottom-0">Avg Price/ Sq.ft
                            <OverlayTrigger delay={{ hide: 150, show: 200 }} overlay={(props) => (
                                <Tooltip {...props} > Avg Price/ Sq.ft</Tooltip>
                            )} placement={isMobile ? "top" : "right"}>
                                <Icon icon="akar-icons:info-fill" className='position-absolute i-icon' style={{ "color": "#43A66A", "right": "8px", "top": "6px" }} />
                            </OverlayTrigger>
                        </div>
                        <div className="card-body bg-white p-2 pt-1 pb-1">
                            <span className="card-title fw-bold">
                                {isNaN(minPrice) || isNaN(maxPrice) || minPrice === 0 || maxPrice === 0 ? (
                                    <span className="text-primary" style={{ "cursor": "pointer" }} onClick={() => handleClickEnquiryForm()}>Price on Request</span>
                                ) : (
                                    // parseInt(minPrice) === 0 ?
                                    // `₹\u00A0${formatNumber((maxPrice / maxCarpet).toFixed(0))}` : `₹\u00A0${formatNumber((minPrice / minCarpet).toFixed(0))}`
                                    //)
                                    quoted_per_sqft_price != "0" && quoted_per_sqft_price != null ? (
                                        `₹\u00A0${numberWithCommas(formatNumber(quoted_per_sqft_price).toFixed(0))}`
                                    ):(
                                        'NA'
                                    )
                                )}
                            </span>

                        </div>
                    </div>
                    <div className="card border-primary text-dark">
                        <div className="card-header p-2 pt-1 pb-1 fw-bold border-bottom-0">Total Price
                            <OverlayTrigger delay={{ hide: 150, show: 200 }} overlay={(props1) => (
                                <Tooltip {...props1}> Total Price = Agreement Price + SDR + GST*.
                                    (*The actual total price may vary depending on other charges)</Tooltip>
                            )} placement={isMobile ? "top" : "right"}>
                                <Icon icon="akar-icons:info-fill" className='position-absolute i-icon' style={{ "color": "#43A66A", "right": "8px", "top": "6px" }} />
                            </OverlayTrigger>
                        </div>
                        <div className="card-body bg-white p-2 pt-1 pb-1">
                            <span className="card-title fw-bold">
                                {isNaN(minPrice) || isNaN(maxPrice) || minPrice === 0 || maxPrice === 0 ? (
                                    <span className="text-primary" style={{ "cursor": "pointer" }} onClick={() => handleClickEnquiryForm()}>Price on Request</span>
                                ) : (
                                    minPrice === maxPrice ? (
                                        `₹\u00A0${formatNumber(minPrice)}`  // Using Unicode for non-breaking space
                                    ) : (
                                        `₹\u00A0${formatNumber(minPrice)} - ${formatNumber(maxPrice)}`
                                    )
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="card border-primary text-dark position-none d-none d-sm-block">
                        <div className="card-header p-2 pt-1 pb-1 fw-bold border-bottom-0  position-relative">EMI Starts At
                            {/* <OverlayTrigger delay={{ hide: 450, show: 300 }} overlay={(props2) => ( 
                            <Tooltip {...props2}> Total Price = Ag Price + SDR + GST*.
                            (*The actual total price may vary depending on other charges)</Tooltip> 
                        )} placement="right">   
                            <Icon icon="akar-icons:info-fill" className='position-absolute'  style={{"color": "#43A66A", "right":"8px", "top":"6px"}} />
                        </OverlayTrigger> */}
                        </div>
                        <div className="card-body bg-white p-2 pt-1 pb-1">
                            <span className="card-title fw-bold ">
                                {isNaN(minPrice) || isNaN(maxPrice) || minPrice === 0 || maxPrice === 0 ? (
                                    <span className="text-primary" style={{ "cursor": "pointer" }} onClick={() => handleClickEnquiryForm()}>Price on Request</span>
                                ) : (
                                    <><div className='emiCalcPopup1' onClick={() => handleEMIClick()}>
                                        <a href='javascript:;' className='text-decoration-none'>
                                            <EMICalculator closePopup={handleCloseEMIPopup} loanPrice={maxPrice} showEMI={emiPopup} />
                                        </a>
                                    </div>
                                        {/* <EMICalculator loanPrice={maxPrice} showEMI={emiPopup} /> */}
                                    </>
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </div></>
    );
};
const ProjectCardListing = ({ project, selectedTab, appAbsPath, isWishlistActive, loggeduserdetails, locality_name, handleLoginCoupon, isRecommended = false }) => {
    const [selectedConfig, setSelectedConfig] = useState(selectedTab[0] || null);
    const [showAll, setShowAll] = useState(false);
    // const [isActive, setIsActive] = useState(false);
    // const [selectedItems, setSelectedItems] = useState([]);
    const { isProjectSlug, setProjectSlug } = useContext(CompareProjectContext);
    const { isProjectId, setProjectId } = useContext(CompareProjectContext);
    const [isChecked, setIsChecked] = useState(false);

    // console.log("Selected Tab: ", selectedTab);
    // const [wishListedFetched, setWishListedFetched] = useState(false);
    // const bhkTypes = ["2BHK"];
    const bhkTypesWithoutSpaces = selectedTab.map(type => type.replace(/\s/g, ' ').toUpperCase());
    // console.log(bhkTypesWithoutSpaces);
    // const suggestionContainerRef = useRef(null);
    // const handleOutsideClick = (e) => {
    //     if (
    //     suggestionContainerRef.current &&
    //     !suggestionContainerRef.current.contains(e.target) &&
    //     emiCalRef.current &&
    //     !emiCalRef.current.contains(e.target)
    //     ) {
    //         showSearchResult(false);
    //     }
    // };

    // useEffect(() => {
    //     document.addEventListener('mousedown', handleOutsideClick);
    //     return () => {
    //       document.removeEventListener('mousedown', handleOutsideClick);
    //     };
    // }, []);
    useEffect(() => {
        if (project) {
            const uniqueConfigs = getUniqueConfigurations();
            if (bhkTypesWithoutSpaces.length > 0) {
                setSelectedConfig(bhkTypesWithoutSpaces[0]);
            } else {
                setSelectedConfig(uniqueConfigs[0]);
            }
        }
        lazyLoadImages();
        //console.log("Selected Config: ", selectedConfig);
    }, [project, selectedTab]); // Remove selectedConfig from the dependency array


    const handleConfigClick = (configName) => {
        setSelectedConfig(configName);
    };
    const getMinMaxCarpet = (configurationName) => {
        let minCarpet = Infinity;
        let maxCarpet = -Infinity;

        project.towers.forEach((tower) => {
            tower.configurations.forEach((config) => {
                if (config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase() === configurationName) {
                    const carpet = parseFloat(config.carpet);
                    minCarpet = Math.min(minCarpet, carpet);
                    maxCarpet = Math.max(maxCarpet, carpet);
                }
            });
        });

        return { minCarpet, maxCarpet };
    };
    const getMinMaxPrice = (configurationName) => {
        let minPrice = Infinity;
        let maxPrice = -Infinity;
        project.towers.forEach((tower) => {
            tower.configurations.forEach((config) => {
                if (config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase() === configurationName) {
                    const price = parseInt(config.final_price);
                    minPrice = Math.min(minPrice, price);
                    maxPrice = Math.max(maxPrice, price);
                }
            });
        });

        return { minPrice, maxPrice };
    };
    const getUniqueConfigurations = () => {
        const configurationsSet = new Set();
        if (project.towers === undefined) return Array.from(configurationsSet).sort();
        project?.towers.forEach((tower) => {
            tower?.configurations.forEach((config) => {
                const processedName = config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase();
                configurationsSet.add(processedName);
            });
        });
        return Array.from(configurationsSet).sort();
    };
    if (!project) {
        return <Loader />;
    }

    const uniqueConfigs = getUniqueConfigurations();

    const handleCheckboxChange = (e) => {
        const { checked, value } = e.target;
        let projectID = e.target.getAttribute('projectid');

        setIsChecked(checked);
        if (isProjectSlug.length >= 4) {
            setIsChecked(false);
            showToastMessage('Only 4 project compare!', 'top-right', 'error');
            return;
        }
        setProjectSlug(prevSlug => {
            if (checked) {
                // If the checkbox is checked, add the value to the array
                return [...prevSlug, value];
            } else {
                // If the checkbox is not checked, remove the value from the array
                return prevSlug.filter(slug => slug !== value);
            }
        });

        setProjectId(prevId => {
            if (checked) {
                // If the checkbox is checked, add the value to the array
                return [...prevId, projectID];
            } else {

                return prevId.filter(pid => pid !== projectID);
            }
        });

    };
    const handleNotLogin = () => {
        // setLoginTrue(true);
        document.getElementById('overlay').style.display = 'block';
        document.getElementById('login_title').textContent = "Stay updated with the latest transactions";
    }

    return (
        <div className={project.is_irfs === true && project.offers && Array.isArray(project.offers) && project.offers.length > 0 ? 'irfslist-class' : ''}>
            <div className="tabcontent rounded-8 plp-card mb-3" id={project.id}>
                <div class="irfs-exl">IRFS Exclusive Offer</div>
                <div className="d-flex flex-lg-row flex-column rounded gap-xxl-4 gap-sm-4 gap-3 plp-card-main">
                    <div className="image-side position-relative">
                        {project && project.gallery_thumbnail && Array.isArray(project.gallery_thumbnail) && project.gallery_thumbnail.length > 0 ? (
                            (() => {
                                return (  // Added return statement here
                                    project.gallery_thumbnail.length > 1 ? (
                                        <Carousel data-bs-theme="light">
                                            {project.gallery_thumbnail.map((image, index) => (
                                                checkFileExtension(image.url) && (
                                                    <Carousel.Item key={index}>
                                                        <Link to={project.is_irfs ? `${appAbsPath}/irfs/project/${project.slug}` : `${appAbsPath}/project/${project.slug}`}
                                                            onClick={() => { createGa4Event("project_view", 'Project View', `${project.name}`); }}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className='text-decoration-none'>
                                                            <img src={`${s3url}/project-assets${image.url}`} alt={`Slide ${index}`} />
                                                        </Link>
                                                    </Carousel.Item>
                                                )
                                            ))}
                                        </Carousel>
                                    ) : (
                                        project.gallery_thumbnail.map((image, index) => (
                                            checkFileExtension(image.url) && (
                                                <Link key={index} to={project.is_irfs ? `${appAbsPath}/irfs/project/${project.slug}` : `${appAbsPath}/project/${project.slug}`}
                                                    onClick={() => { createGa4Event("project_view", 'Project View', `${project.name}`); }}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className='text-decoration-none'>
                                                    <img src={`${s3url}/project-assets${image.url}`} alt={`Single Image ${index}`} />
                                                </Link>
                                            )
                                        ))
                                    )
                                );
                            })()
                        ) : project && project.gallery && Array.isArray(project.gallery) && project.gallery.length > 0 ? (
                            <Carousel data-bs-theme="light">
                                {project.gallery.map((image, index) => (
                                    checkFileExtension(image.url) && (
                                        <Carousel.Item key={project.id + '-' + index}>
                                            <Link to={project.is_irfs === true && project.offers && Array.isArray(project.offers) && project.offers.length > 0 ? `${appAbsPath}/irfs/project/${project.slug}` : `${appAbsPath}/project/${project.slug}`}
                                                onClick={() => { createGa4Event("project_view", 'Project View', `${project.name}`); }} target="_blank" rel="noopener noreferrer" className='text-decoration-none'>
                                                <img src={`${s3url}/project-assets${image.url}`} alt={`Slide ${index}`} />
                                            </Link>
                                        </Carousel.Item>
                                    )
                                ))}
                            </Carousel>
                        ) : (
                            <>
                                <div className='lazyload-placeholder project-img' style={{ backgroundImage: `url(${defaultGallery})` }}></div>
                            </>
                        )}
                        <WishListBtn projectId={project.id} isWishlistActives={isWishlistActive} loggeduserdetails={parsedUserData} color={"#fff"} width={"37px"} height={"37px"} handleNotLogin />
                        {/* {console.log("Not Login", isLogin)} */}
                        <div className="rera-main-div">
                            {project.possession && (
                                <p className='pos-date'><span>Possession :</span> {convertShortDate(project.possession)}</p>
                            )}
                            <div className='list-rerabox'>
                                <p className="rera-txt">
                                    {/*<Icon icon="material-symbols:check" color={"#fff"} />*/}
                                    RERA Certified
                                </p>
                                <div className="zindex-10 top-picks">
                                    <div className="d-flex flex-column text-center">
                                        <span className="fw-bold"><Icon icon="mingcute:star-fill" className='mb-1' color={"#ffce31"} />
                                            {/* {dateDiffInMonths(project.completion_timestamp, new Date()) <= 3 ? 'Ready To Move' : project.project_status == '' ? dateDiffInMonths(project.completion_timestamp, new Date()) > 3 && dateDiffInMonths(project.completion_timestamp, new Date()) < 24 ? 'Under Construction' : 'New Project' : project.project_status} */}
                                            {project.project_status ? project.project_status : 'Under Construction'}
                                        </span>
                                        {/* <span className="ready-since text-white">(Since Jun, 2021)</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="rera-number-div">
                        <h6>MAHA RERA - REGISTRATION NUMBER</h6>
                        <div>
                            <div className="parent">
                                <div className="div1">
                                    <span className="psn-details">{(project?.rera_registration_no)?.split(',').join(', ').replace(/"/g, '').replace("[", '').replace("]", '')}</span>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    </div>
                    <div className="flex-grow-1 flex-basis-0 position-relative pe-0 pe-md-4 listcard-cont">
                        <div className="pb-2 position-relative listpad">
                            <div className="rightBtnsWrapper d-flex justify-content-end gap-2 align-items-center">
                                {/* <Link className="shareBtn">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24">
                                    <path fill="black" d="M16.61 21q-.994 0-1.687-.695q-.692-.696-.692-1.69q0-.15.132-.757l-7.197-4.273q-.324.374-.793.587t-1.007.213q-.986 0-1.676-.702T3 12t.69-1.683t1.676-.702q.537 0 1.007.213t.793.588l7.198-4.255q-.07-.194-.101-.385q-.032-.192-.032-.392q0-.993.697-1.689Q15.625 3 16.62 3t1.688.697T19 5.389t-.695 1.688t-1.69.692q-.542 0-1-.222t-.78-.597l-7.199 4.273q.07.194.101.386q.032.191.032.391t-.032.391t-.1.386l7.198 4.273q.323-.375.78-.597q.458-.222 1-.222q.994 0 1.69.696q.695.698.695 1.693t-.697 1.688t-1.692.692m.004-1q.589 0 .987-.398t.398-.986t-.398-.987t-.986-.398t-.987.398t-.398.986t.398.987t.987.398m-11.25-6.616q.596 0 1-.398q.403-.398.403-.986t-.403-.986t-1-.398q-.581 0-.973.398T4 12t.393.987t.973.397m11.25-6.615q.588 0 .986-.398T18 5.384t-.398-.986T16.616 4t-.987.398t-.398.987t.398.986t.987.398m0-1.385" />
                                </svg>
                            </Link> */}

                                <div className={`d-none compare-btn text-end position-relative ${isChecked ? 'checked' : ''}`}>
                                    <input
                                        id={`checkbox-${project.slug}`}
                                        type="checkbox"
                                        checked={isChecked}
                                        value={project.slug}
                                        projectid={project.id}
                                        onChange={handleCheckboxChange}
                                    // onClick={createGa4Event('On Click' , 'Compare', project.name)}

                                    // disabled={isProjectSlug.length >= 4 && !isChecked}
                                    />
                                    {/* <input id={project.slug} name={project.slug} type="checkbox" checked={selectedItems.includes(project.slug)} value={project.slug} onChange={handleCheckboxChange} /> */}
                                    {!isChecked ? (
                                        <svg viewBox="0 0 100 100" className='compare-btn' for={`checkbox-${project.slug}`} xmlns="http://www.w3.org/2000/svg">
                                            <text x="50" y="50" text-anchor="middle" dominant-baseline="middle">Compare</text>
                                        </svg>
                                    ) : (
                                        <><svg xmlns="http://www.w3.org/2000/svg" width="102.831" height="30" viewBox="0 0 102.831 33">
                                            <g id="Group_69600" data-name="Group 69600" transform="translate(-841.169 -2919)">
                                                <g id="Rectangle_141309" data-name="Rectangle 141309" transform="translate(841.169 2919)" fill="none" stroke="#667486" stroke-width="1">
                                                    <rect width="102.831" height="33" rx="8" stroke="none" />
                                                    <rect x="0.75" y="0.75" width="101.331" height="31.5" rx="7.25" fill="none" />
                                                </g>
                                                <g id="Group_68436" data-name="Group 68436" transform="translate(15452.183 26355.316)">
                                                    <text id="Added" transform="translate(-14598 -23414.588)" fill="#667486" font-size="14" font-weight="700"><tspan x="0" y="0">Added</tspan></text>
                                                </g>
                                                <path id="Path_88338" data-name="Path 88338" d="M94.221,96.185l-11.785,9.848-4.6-6.3,1.953-1.638,3.064,4.195,9.815-8.21Z" transform="translate(838.935 2835.444)" fill="#667486" stroke="rgba(0,0,0,0)" stroke-width="1" fill-rule="evenodd" />
                                            </g>
                                        </svg>
                                        </>
                                    )}
                                </div>
                                <ShareButtonWithPopup projecturl={`${window.location.origin}/project/${project.slug}`} />

                            </div>
                            <Link to={project.is_irfs === true && project.offers && Array.isArray(project.offers) && project.offers.length > 0 ? `${appAbsPath}/irfs/project/${project.slug}` : `${appAbsPath}/project/${project.slug}` } onClick={() => { createGa4Event("project_view", 'Project View', `${project.name}`) }} target="_blank" rel="noopener noreferrer" className='text-secondary text-capitalize text-decoration-none' >
                                <h2 className="plpcard-name">{project.name}</h2>
                            </Link>
                            <p className='mb-0 developer-name'>By <span>{project.developer.name}</span>, {project?.address?.location}, {project?.address?.city}, {project?.address?.zip}</p>
                            <div className="d-inline bhk-tab nav fs-6">
                                {uniqueConfigs.map((configName, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        className={`nav-link d-inline unitConfig p-0 bhk-color ps-2 pe-2 mt-2 me-2 ${(!selectedConfig && index === 0) || selectedConfig === configName ? 'active' : ''}`}
                                        onClick={() => [handleConfigClick(configName), createGa4Event("project_congifurations", 'Project Congifurations', `${project.name} - ${configName}`)]}>
                                        {configName}
                                    </button>
                                ))}
                            </div>
                            <div className='d-block d-sm-none carpetmobile'>
                                {selectedConfig &&
                                    <div>
                                        {project.towers && project &&
                                            project?.towers.map((tower, towerIndex) => {
                                                const matchingConfig = tower.configurations.find(config => config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase() === selectedConfig);
                                                if (matchingConfig) {
                                                    const { minCarpet, maxCarpet } = getMinMaxCarpet(selectedConfig);
                                                    const { minPrice, maxPrice } = getMinMaxPrice(selectedConfig);
                                                    const isFirstOccurrence = project.towers
                                                        .slice(0, towerIndex)
                                                        .every(otherTower => !otherTower.configurations.some(config => config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase() === selectedConfig));
                                                    if (isFirstOccurrence) {
                                                        return (
                                                            <div className="mt-2">
                                                                <p className="text-mob-comparision plp-carpet-area">Carpet area: <span
                                                                    className="text-listing fw-bold">
                                                                    {minCarpet.toFixed(0) === maxCarpet.toFixed(0) ?
                                                                        numberWithCommas(minCarpet.toFixed(0))
                                                                        :
                                                                        numberWithCommas(minCarpet.toFixed(0)) + " - " + numberWithCommas(maxCarpet.toFixed(0))
                                                                    } sq.ft.</span>
                                                                </p>
                                                            </div>
                                                        );
                                                    }
                                                }
                                                return null;
                                            })}
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="plp-card-detail border-bottom col-12 col-md-12 border-top border-1">
                            {project.amenities.length > 2 && (
                                <div className="d-flex flex-wrap amenities-gap align-content-center">
                                    {Array.isArray(project.amenities) && project.amenities.length > 0 ? (

                                        (showAll ? project.amenities : project.amenities).map((amenity, index) => (

                                            <>{amenity.is_featured &&
                                                <div key={index} className="plp-amenties-tag" title={amenity.name.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, c => c.toUpperCase())}>
                                                    <p className="m-0 text-property text-truncate fs-14">{amenity.name.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, c => c.toUpperCase())}</p>
                                                </div>}</>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                    {project.amenities.length > 5 && project.amenities.some(amenity => amenity.is_featured) && (
                                        <Link to={project.is_irfs === true && project.offers && Array.isArray(project.offers) && project.offers.length > 0 ? `${appAbsPath}/irfs/project/${project.slug}` : `${appAbsPath}/project/${project.slug}`} onClick={() => { createGa4Event("project_view", 'Project View', `${project.name}`) }} target="_blank"
                                        rel="noopener noreferrer" className="p-2 readmore  text-decoration-underline  background-trasparent btn btn-link">{project.amenities.length}+ </Link>
                                    )}
                                </div>
                            )}
                            <div className="tab-content col-12 d-flex d-sm-block align-items-center justify-content-between">
                                {selectedConfig &&
                                    <div className='d-flex d-sm-block align-items-center'>
                                        <span className='d-block d-sm-none'>Total Price:</span>
                                        {project.towers && project &&
                                            project?.towers.map((tower, towerIndex) => {
                                                const matchingConfig = tower.configurations.find(config => config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase() === selectedConfig);
                                                if (matchingConfig) {
                                                    const { minCarpet, maxCarpet } = getMinMaxCarpet(selectedConfig);
                                                    const { minPrice, maxPrice } = getMinMaxPrice(selectedConfig);
                                                    const isFirstOccurrence = project.towers
                                                        .slice(0, towerIndex)
                                                        .every(otherTower => !otherTower.configurations.some(config => config.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase() === selectedConfig));
                                                    if (isFirstOccurrence) {
                                                        return (
                                                            <ConfigurationDetails
                                                                key={towerIndex}
                                                                configuration={matchingConfig}
                                                                minCarpet={minCarpet}
                                                                maxCarpet={maxCarpet}
                                                                minPrice={minPrice}
                                                                maxPrice={maxPrice}
                                                                project={project}
                                                                quoted_per_sqft_price={project.quoted_per_sqft_price}
                                                            />
                                                        );
                                                    }
                                                }
                                                else{
                                                    if(isRecommended){
                                                        try {
                                                            const lowestBedroomConfig = tower?.configurations.reduce((minConfig, currentConfig) => {
                                                                return currentConfig.bedrooms < minConfig.bedrooms ? currentConfig : minConfig;
                                                            }, tower?.configurations[0]);
                                                        
                                                            if (!lowestBedroomConfig) {
                                                                // throw new Error("No valid bedroom configuration found.");
                                                                // return null;
                                                            }
                                                                                                                   
                                                            const { minCarpet, maxCarpet } = getMinMaxCarpet(lowestBedroomConfig.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase());
                                                            const { minPrice, maxPrice } = getMinMaxPrice(lowestBedroomConfig.name.replace(/(\d)\s*(?=\D)/g, '$1 ').replace(/\s*\.\s*/g, '.').toUpperCase());
                                                            
                                                            return (
                                                                <ConfigurationDetails
                                                                    key={towerIndex}
                                                                    minCarpet={minCarpet}
                                                                    maxCarpet={maxCarpet}
                                                                    minPrice={minPrice}
                                                                    maxPrice={maxPrice}
                                                                    project={project}
                                                                    quoted_per_sqft_price={project.quoted_per_sqft_price}
                                                                />
                                                            );
                                                        } catch (error) {
                                                            // console.error("Error occurred while processing configurations:", error);
                                                            return null;
                                                        }
                                                    }
                                                }
                                                return null;
                                            })}
                                    </div>
                                }
                            </div>

                        </div>
                        <div className='bgorange justify-content-between align-items-center'>
                            {project.is_irfs === true && project.offers && Array.isArray(project.offers) && project.offers.length > 0 && (

                                <>
                                    <div className='d-block d-sm-none'>
                                        <CouponButtons
                                            project={project}
                                            parsedUserData={parsedUserData}
                                            handleLoginCoupon={handleLoginCoupon}
                                            handleNotLogin={handleNotLogin}
                                            buttonText="Claim Offer"
                                        />
                                    </div>
                                    {/* /* <div className='discdiv'>
                                    <img src={Discount} className="img-fluid me-2 discimg"/>
                                    <Carousel data-bs-theme="light" controls={false} indicators={false}>
                                    {Array.isArray(project?.offers) && project.offers.map((offer, offersIndex) => (
                                        <Carousel.Item key={offersIndex}>
                                            <div className='d-flex'>
                                                <p className='text-grey mb-1'>Offer:</p>
                                                <p className='irfsdisc mb-1'>{offer.name}</p>
                                            </div>
                                        </Carousel.Item>
                                    ))}
                                    </Carousel>
                                </div> */}
                                    <OfferTag project={project} />
                                    <div className='d-none d-sm-block position-relative discbtn'>
                                        <CouponButtons
                                            project={project}
                                            parsedUserData={parsedUserData}
                                            handleLoginCoupon={handleLoginCoupon}
                                            handleNotLogin={handleNotLogin}
                                            buttonText="Claim Offer"
                                        />
                                    </div>

                                </>
                            )}
                        </div>

                        <div className='mt-1'>
                            <Gamification projectDetails={project} />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectCardListing;
