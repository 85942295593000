import React, { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import Slider from 'rc-slider'; // Correct import from 'rc-slider'
import 'rc-slider/assets/index.css'; // Ensures slider styles are applied
import { formatNumber, decodeUrlParams } from '../../Admin/Utils';
// import $ from 'jquery';
import { createGa4Event } from '../../Admin/Utils';
import { setProjectStatusFilter, setProjectConfigurationFilter, setProjectMaxBudgetFilter } from '../../Admin/UserActivatyTracker';
import { useLocation } from 'react-router-dom';
import { set } from 'mongoose';
// import StickyBox from "react-sticky-box";

const FilterSidebar = ({
    selectedStatus, toggleStatus, //sortProjectsByStatus,
    togglePossessionStatus, selectedPossessionStatus, filteredByBhk, initialDisplayLimit, showAllBhk, toggleShowAllBhk, selectedBHK,
    toggleBhkType, sortProjectsByBhkType, setSelectedTab,
    priceRange, builtUpArea, handleSliderChange, handleSliderChangeArea, handleSliderChangeComplete, handleSliderChangeCompleteArea,
    getProjectstatusData, clearUrlParams, hasmore
}) => {
    const [hasParams, setHasParams] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        setHasParams(urlParams.toString().length > 0);
    }, [location.search]); // Triggers whenever URL params change

    // React.useEffect(() => {
    //     const handleScroll = () => {
    //         const fixedDiv = document.getElementById('filter-sticky');
    //         const footer = document.getElementById('footerdiv');

    //         if (fixedDiv && footer) {
    //             const footerRect = footer.getBoundingClientRect();
    //             const fixedDivRect = fixedDiv.getBoundingClientRect();

    //             // if (window.scrollY > 88 && footerRect.top > window.innerHeight) {
    //             //     fixedDiv.classList.add('stickySidebar');
    //             //     fixedDiv.style.position = 'fixed';
    //             //     fixedDiv.style.bottom = 'initial';
    //             //     footer.style.visibility = 'hidden';
    //             // } else if (footerRect.top <= window.innerHeight) {
    //             //     fixedDiv.classList.remove('stickySidebar');
    //             //     fixedDiv.style.position = 'absolute';
    //             //     fixedDiv.style.bottom = '0';
    //             //     fixedDiv.classList.add('col-md-12');
    //             //     footer.style.visibility = 'visible';
    //             // } else {
    //             //     fixedDiv.classList.remove('stickySidebar');
    //             //     fixedDiv.style.position = 'relative';
    //             //     fixedDiv.style.bottom = 'initial';
    //             //     fixedDiv.classList.add('col-md-12');
    //             //     footer.style.visibility = 'hidden';
    //             // }
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    const [isSticky, setIsSticky] = useState(false);

    const handleScroll = () => {
        // Change the threshold value (100) as needed
        const footer = document.getElementById('footerdiv');
        const footerTop = footer.getBoundingClientRect().top;
        // console.log('fedfegrgfrf',footerTop)
        // console.log('hasmore',hasmore)

        if (window.scrollY > 100 && hasmore === true) {
            setIsSticky(true);
            // console.log('scrolled more than 100')
        } else if (window.scrollY > 100 && hasmore === false && footerTop < 700 && footerTop != 0) {
            // console.log('footer TOuch trueeee')
            setIsSticky(false);
        } 
        else if (window.scrollY < 100){
            // console.log('top touch truee')
            setIsSticky(false);
        }
         else {
            // console.log('dont knowww')
            setIsSticky(true);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    });

    const combinedOnChange = (newRange) => {
        handleSliderChange(newRange);
        // setProjectMaxBudgetFilter(newRange[1]);
    };
    const combinedOnChangeArea = (newRangeArea) => {
        handleSliderChangeArea(newRangeArea);
        // setProjectMaxBudgetFilter(newRange[1]);
    };

    const combinedOnChangeComplete = (newRange) => {

        // console.log(newRange[1])
        handleSliderChangeComplete(newRange);
        setProjectMaxBudgetFilter(newRange[1]);
        createGa4Event('filter_budget', 'BudgetFilter', newRange[0] + "-" + newRange[1])
    }
    const combinedOnChangeCompleteArea = (newRangeArea) => {

        // console.log(newRange[1])
        handleSliderChangeCompleteArea(newRangeArea);
        // setProjectMaxBudgetFilter(newRange[1]);
        createGa4Event('filter_builtup', 'Built Up Filter', newRangeArea[0] + "-" + newRangeArea[1])
    }



    return (
        <section className="right-side-plp-filter" id="right-side-div">
            <section className={`plp-filter-dropdown bg-white filter-sticky col-12 ${isSticky ? "sticky" : ""}`} id='filter-sticky'>
                <div className="d-flex align-items-center justify-content-between pt-2">
                    <h3 className=' ps-0 pb-0 mb-0 d-block'>Filters</h3>
                    {hasParams && (
                        <button
                            className='btn btn-primary sc py-1 h-auto'
                            onClick={() => clearUrlParams(true)}
                        >
                            Clear All
                        </button>
                    )}
                </div>
                <input type="hidden" name="search" value="" id="plp-search-hidden" />
                <Accordion defaultActiveKey={['3']} alwaysOpen className='right-filter-accordation'>
                    <Accordion.Item eventKey="0" className='p-0'>
                        <Accordion.Header>Project Status</Accordion.Header>
                        <Accordion.Body className='filter-wrapbody'>
                            {/* {getProjectstatusData.map((status, index) => (
                                status !== "" && (
                                    <a key={index} className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes(status) ? 'active' : ''}`}
                                        onClick={() => {
                                            toggleStatus(status);
                                            sortProjectsByStatus(selectedStatus);
                                        }}>
                                        {status}
                                    </a>
                                )
                            ))} */}
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${decodeUrlParams(selectedStatus).includes("New Project") ? 'active' : ''}`}
                                onClick={() => {
                                    toggleStatus("New Project");
                                    // sortProjectsByStatus(selectedStatus);
                                    createGa4Event('Click', 'Project Status Filter', 'New Project')
                                }}>
                                New Project
                            </a>
                            {/* <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedStatus.includes("New Launch") ? 'active' : ''}`}
                                onClick={() => {
                                    toggleStatus("New Launch");
                                    setProjectStatusFilter("New Launch");
                                    // sortProjectsByStatus(selectedStatus);
                                    createGa4Event('filter_project_status', 'ProjectStatusFilter', 'New Launch')
                                }}>
                                New Launch
                            </a> */}
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${decodeUrlParams(selectedStatus).includes("Under Construction") ? 'active' : ''}`}
                                onClick={() => {
                                    toggleStatus("Under Construction");
                                    setProjectStatusFilter("Under Construction");
                                    // sortProjectsByStatus(decodeUrlParams(selectedStatus));
                                    createGa4Event('filter_project_status', 'ProjectStatusFilter', 'Under Construction')
                                }}>
                                Under Construction
                            </a>
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${decodeUrlParams(selectedStatus).includes("Ready To Move") ? 'active' : ''}`}
                                onClick={() => {
                                    toggleStatus("Ready To Move");
                                    setProjectStatusFilter("Ready To Move");
                                    // sortProjectsByStatus(decodeUrlParams(selectedStatus));
                                    createGa4Event('filter_project_status', 'ProjectStatusFilter', 'Ready To Move')
                                }}>
                                Ready To Move
                            </a>
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${decodeUrlParams(selectedStatus).includes("New Launch") ? 'active' : ''}`}
                                onClick={() => {
                                    toggleStatus("New Launch");
                                    setProjectStatusFilter("New Launch");
                                    // sortProjectsByStatus(decodeUrlParams(selectedStatus));
                                    createGa4Event('filter_project_status', 'ProjectStatusFilter', 'New Launch')
                                }}>
                                New Launch
                            </a>
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${decodeUrlParams(selectedStatus).includes("Nearing Possession") ? 'active' : ''}`}
                                onClick={() => {
                                    toggleStatus("Nearing Possession");
                                    setProjectStatusFilter("Nearing Possession");
                                    // sortProjectsByStatus(decodeUrlParams(selectedStatus));
                                    createGa4Event('filter_project_status', 'ProjectStatusFilter', 'Nearing Possession')
                                }}>
                                Nearing Possession
                            </a>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" className='border-top'>
                        <Accordion.Header>Configuration Type</Accordion.Header>
                        <Accordion.Body className='filter-wrapbody'>
                            {filteredByBhk && Array.isArray(filteredByBhk) && selectedBHK && Array.isArray(selectedBHK) && filteredByBhk.length > 0 && filteredByBhk.slice(0, showAllBhk ? filteredByBhk.length : initialDisplayLimit).map((BhkType, index) => (
                                BhkType !== "" && (
                                    BhkType && BhkType.bedrooms && BhkType.type &&
                                    <a key={index} className={`btn btn-outline-secondary me-2 mb-2 ${selectedBHK.includes(BhkType.bedrooms + " " + BhkType.type.toUpperCase()) ? 'active' : ''}`}
                                        onClick={() => {
                                            toggleBhkType(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                                            sortProjectsByBhkType(selectedBHK);
                                            setSelectedTab(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                                            setProjectConfigurationFilter(BhkType.bedrooms + " " + BhkType.type.toUpperCase());
                                            createGa4Event('filter_configuration_type', 'ConfigurationTypeFilter', BhkType.bedrooms + " " + BhkType.type.toUpperCase())
                                        }}>
                                        {BhkType.bedrooms} {BhkType.type.toUpperCase()}
                                    </a>
                                )
                            ))}
                            {filteredByBhk.length > initialDisplayLimit && (
                                <button className="p-1 pt-0 readmore text-primary text-decoration-underline fw-bold border-0 background-trasparent btn btn-link" onClick={toggleShowAllBhk}>
                                    {showAllBhk ? '- Show Less' : '+ Show More'}
                                </button>
                            )}
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" className='border-top'>
                        <Accordion.Header>Possession Status</Accordion.Header>
                        <Accordion.Body className='filter-wrapbody'>
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 3 ? 'active' : ''}`}
                                onClick={() => {
                                    togglePossessionStatus('3');
                                    // sortProjectsByStatus(selectedPossessionStatus);
                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 3 months')
                                }}>
                                In 3 months
                            </a>
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 6 ? 'active' : ''}`}
                                onClick={() => {
                                    togglePossessionStatus('6');
                                    // setProjectStatusFilter("In 6 months");
                                    // sortProjectsByStatus(selectedPossessionStatus);
                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 6 months')
                                }}>
                                In 6 months
                            </a>
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 12 ? 'active' : ''}`}
                                onClick={() => {
                                    togglePossessionStatus('12');
                                    // setProjectStatusFilter("In 1 year");
                                    // sortProjectsByStatus(selectedPossessionStatus);
                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 1 year')
                                }}>
                                In 1 year
                            </a>
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 24 ? 'active' : ''}`}
                                onClick={() => {
                                    togglePossessionStatus('24');
                                    // setProjectStatusFilter("In 2 years");
                                    // sortProjectsByStatus(selectedPossessionStatus);
                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 2 years')
                                }}>
                                In 2 years
                            </a>
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 36 ? 'active' : ''}`}
                                onClick={() => {
                                    togglePossessionStatus('36');
                                    // setProjectStatusFilter("In 3 years");
                                    // sortProjectsByStatus(selectedPossessionStatus);
                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 3 years')
                                }}>
                                In 3 years
                            </a>
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 60 ? 'active' : ''}`}
                                onClick={() => {
                                    togglePossessionStatus('60');
                                    // setProjectStatusFilter("In 5 years");
                                    // sortProjectsByStatus(selectedPossessionStatus);
                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 5 years')
                                }}>
                                In 5 years
                            </a>
                            <a className={`btn btn-outline-secondary me-2 mb-2 ${selectedPossessionStatus == 84 ? 'active' : ''}`}
                                onClick={() => {
                                    togglePossessionStatus('84');
                                    // setProjectStatusFilter("In 7 years");
                                    // sortProjectsByStatus(selectedPossessionStatus);
                                    createGa4Event('filter_possession_status', 'PossessionStatusFilter', 'In 7 years')
                                }}>
                                In 7 years
                            </a>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" className='border-top'>
                        <Accordion.Header>Budget</Accordion.Header>
                        <Accordion.Body>
                            <Slider
                                range
                                min={500000}
                                max={50000000}
                                value={priceRange}
                                onChange={combinedOnChange}
                                onChangeComplete={combinedOnChangeComplete}
                                step={50000}

                            />
                            <p className='d-flex justify-content-between'>
                                <span>{`₹${formatNumber(priceRange[0])}`}</span>
                                <span>{`₹${formatNumber(priceRange[1])}`}{priceRange[1] >= 50000000 && '+'}</span>
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4" className='border-top'>
                        <Accordion.Header>Carpet Area (Sq.Ft.)</Accordion.Header>
                        <Accordion.Body>
                            <Slider
                                range
                                min={100}
                                max={5000}
                                value={builtUpArea}
                                onChange={combinedOnChangeArea}
                                onChangeComplete={combinedOnChangeCompleteArea}
                                step={15}

                            />
                            <p className='d-flex justify-content-between'>
                                <span>{`${builtUpArea[0]} Sq.Ft.`}</span>
                                <span>{`${builtUpArea[1]} Sq.Ft.`}{builtUpArea[1] === 10000 && '+'}</span>
                            </p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </section>
        </section>
    );
};

export default FilterSidebar;
