import React, { useEffect, useRef, useCallback, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';
import { setProjectSearchLocation, setProjectSearchDeveloper, setProjectSearchConfiguration, setProjectMaxBudgetFilter, setProjectSearchProjectStatus, setProjectSearchPossessionMonths } from '../../Admin/UserActivatyTracker';
import { createGa4Event, executeLaravelAPI, decodeUrlParams } from '../../Admin/Utils';
import searchDataJson from '../../../Assets/search-json/pune_master_search.json';
import { debounce } from 'lodash';
import { SearchVisibilityContext } from './SearchVisibilityContext';

const SearchInput = ({ onSearchResults, closeSelectedCallback, handleClose, isLocality = false, irfs = false, autoOpen = false }) => {
  const selectHeaderRef = useRef();
  const hiddenInputRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchNlpQuery, setSearchNlpQuery] = useState('');
  const [selectedItemsState, setSelectedItemsState] = useState([]);
  const { universalLoader, setUniversalLoader, setSelectedValuesCount, selectedValuesCount } = useContext(SearchVisibilityContext);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  function sanitizeJSONResponse(response) {
    try {
      let sanitized = response
        .replace(/^```json\n/, '')
        .replace(/```$/, '')
        .trim();
      return JSON.parse(sanitized);
    } catch (error) {
      throw new Error(`Failed to sanitize or parse response: ${response}`);
    }
  }

  const debouncedNlpSearch = useCallback(
    debounce(async (query) => {
      try {
        // alert('debouncedNlpSearch');
        if (location.pathname === '/irfs' || location.pathname === '/') {
          setUniversalLoader(true);
        }
        const searchNlpPromise = await executeLaravelAPI('searchnlp', { query }, 'GET');
        const searchNlpResult = sanitizeJSONResponse(searchNlpPromise);
        updateUrlParamsWithNlpResult(searchNlpResult, query);
      } catch (error) {
        console.error('Error in NLP search:', error);
      } finally {
        // alert('debouncedNlpSearchfalseeeee');
        if (location.pathname === '/irfs' || location.pathname === '/') {
          setUniversalLoader(false);
        }
      }
    }, 300),
    [location.pathname]
  );

  const updateUrlParamsWithNlpResult = useCallback((searchNlpResult, query) => {
    const urlParams = new URLSearchParams(location.search);
    const { property_configuration, property_location, property_min_budget, property_max_budget, project_status, possession_months } = searchNlpResult;
    // urlParams.set('nlp', '1');
    if (property_configuration) {
      const matches = property_configuration.match(/(\d+(\.\d+)?)\s*([a-zA-Z]*)/);
      if (matches && matches.length >= 3) {
        const bhk = matches[1];
        urlParams.set('bhk', bhk);
        urlParams.set('configuration', `${bhk} BHK`);
        urlParams.set('type', 'bhk');
        setProjectSearchConfiguration(property_configuration);
        createGa4Event('search_configuration_type_nlp', 'ConfigurationTypeSearchNlp', property_configuration);
      }
    }
    if (property_location) {
      urlParams.set('locality_name', decodeUrlParams(property_location));
      urlParams.set('searchtype', 'locality_name');
      setProjectSearchLocation(decodeUrlParams(property_location));
      createGa4Event('search_locality_nlp', 'LocalitySearchNlp', decodeUrlParams(property_location));
    }
    if (property_max_budget) {
      urlParams.set('maxprice', property_max_budget);
      setProjectMaxBudgetFilter(property_max_budget);
      createGa4Event('search_budget_nlp', 'BudgetSearchNlp', property_max_budget);
    }
    if (property_min_budget) {
      urlParams.set('minprice', property_min_budget);
      setProjectMaxBudgetFilter(property_min_budget);
      createGa4Event('search_budget_nlp', 'BudgetSearchNlp', property_min_budget);
    }
    if (project_status) {
      urlParams.set('project_status', project_status.replace('%2B', '+'));
      setProjectSearchProjectStatus(project_status);
      createGa4Event('search_project_status_nlp', 'ProjectStatusSearchNlp', project_status);
    }
    if (possession_months) {
      urlParams.set('possession_months', possession_months);
      setProjectSearchPossessionMonths(possession_months);
      createGa4Event('search_possession_nlp', 'PossessionSearchNlp', possession_months);
    }

    urlParams.set('nlp', '1');
    // urlParams.set('query', query);

    const baseUrl = irfs ? '/irfs/projects' : '/projects';
    const url = `${baseUrl}${location.pathname.includes('map-view') || location.pathname.includes('map-view?') || location.pathname.includes('/map-view?') ? '/map-view' : ''}${urlParams.toString() !== '' ? `?${urlParams.toString()}` : ''}`;
    navigate(url);
  }, [location, navigate, irfs]);

  const updateUrlParams = useCallback((selectedValues, action, removedValue = null) => {
    const urlParams = new URLSearchParams(location.search);

    let localities = urlParams.get('locality_name') ? urlParams.get('locality_name').split(',') : [];
    let configurations = urlParams.get('bhk') ? urlParams.get('bhk').split(',') : [];
    let projectSlug = '';
    let hasNlp = false;
    let nlpQuery = '';
    //console.log('selectedValues: ', selectedValues, 'action: ', action, 'removedValue: ', removedValue);  
    if (action === 'select') {
      selectedValues.forEach(selectedData => {
        switch (selectedData.type) {
          case 'configuration':
            if (!configurations.includes(selectedData.bhk)) {
              configurations.push(selectedData.bhk);
              setProjectSearchConfiguration(selectedData.bhk);
              createGa4Event('search_configuration_type', 'ConfigurationTypeSearch', selectedData.bhk);
            }
            break;
          case 'project':
            createGa4Event('search_project', 'ProjectSearch', selectedData.text);
            projectSlug = selectedData.slug;
            break;
          case 'developer':
            urlParams.set('searchtype', 'developers');
            urlParams.set('developer', selectedData.id);
            setProjectSearchDeveloper(selectedData.id);
            createGa4Event('search_developer', 'DeveloperSearch', selectedData.text);
            break;
          case 'locality':
            if (!localities.includes(selectedData.text)) {
              localities.push(selectedData.text);
              setProjectSearchLocation(selectedData.text);
              createGa4Event('search_locality', 'LocalitySearch', selectedData.text);
            }
            break;
          case 'locality_by_slug':
            if (!localities.includes(selectedData.text)) {
              localities.push(selectedData.text);
              setProjectSearchLocation(selectedData.text);
              createGa4Event('search_locality', 'LocalitySearch', selectedData.text);
            }
            break;
          case 'nlptext':
            hasNlp = true;
            setSearchNlpQuery(selectedData.query);
            debouncedNlpSearch(selectedData.query);
            break;
        }
      });
    } else if (action === 'unselect' && removedValue) {
      //console.log('removedValue', removedValue.text);
      //console.log('localities', localities);
      localities = localities.filter(locality => locality.toLowerCase() !== removedValue.text.toLowerCase());
      // configurations = configurations.filter(config => config == removedValue.bhk);
      let ls = localStorage.getItem('selectedValues');
      //console.log('ls', ls);  
      // ls.forEach((item, index) => {
      //   if (item.text == removedValue.text) {
      //     ls.splice(index, 1); 
      //   }
      // });
      // localStorage.setItem('selectedValues', ls);

      let lp = localStorage.getItem('searchParams');
      //console.log('lp', lp);
      // lp.forEach((item, index) => {
      //   if (item.text == removedValue.text) {
      //     lp.splice(index, 1);
      //   }
      // });
      // localStorage.setItem('selectedParams', lp);

      // //console.log('localities', localities);

      // switch (removedValue.type) {
      //   case 'configuration':
      //     configurations = configurations.filter(config => config !== removedValue.bhk);
      //     break;
      //   case 'developer':
      //     urlParams.delete('searchtype');
      //     urlParams.delete('developer');
      //     break;
      //   case 'locality':
      //   case 'locality_by_slug':
      //     localities = localities.filter(locality => locality !== removedValue.text);
      //     break;
      //   case 'nlptext':
      //     hasNlp = false;
      //     nlpQuery = '';
      //     break;
      // }
    }

    if (localities.length > 0) {
      urlParams.set('locality_name', localities.join(','));
      urlParams.set('searchtype', 'locality_name');
    } else {
      urlParams.delete('locality_name');
      if (urlParams.get('searchtype') === 'locality_name') {
        urlParams.delete('searchtype');
      }
    }

    if (configurations.length > 0) {
      urlParams.set('bhk', configurations.join(','));
      urlParams.set('configuration', configurations.map(config => `${config} BHK`).join(',').replace(/%2B/g, '+'));
      urlParams.set('type', 'bhk');
    } else {
      urlParams.delete('bhk');
      urlParams.delete('configuration');
      urlParams.delete('type');
    }

    // if (hasNlp) {
    //   urlParams.set('nlp', '1');
    //   urlParams.set('query', nlpQuery);
    // } else {
    //   urlParams.delete('nlp');
    //   urlParams.delete('query');
    // }

    if (!hasNlp) {
      const baseUrl = irfs ? '/irfs/projects' : '/projects';
      const url = projectSlug
        ? `project/${projectSlug}`
        : `${baseUrl}${location.pathname.includes('/map-view') ? '/map-view' : ''}${urlParams.toString() !== '' ? `?${urlParams.toString()}` : ''}`;

      navigate(url);
    }


    if (onSearchResults) {
      onSearchResults(selectedValues);
    }
  }, [location, navigate, irfs, onSearchResults, setSearchNlpQuery]);

  const storeSelectedValues = useCallback((selectedValues) => {
    console.log('selectedValues', selectedValues.length);
    if (selectedValues.length > 0) {
      setSelectedValuesCount(selectedValues.length);
    }
    localStorage.setItem('selectedValues', JSON.stringify(selectedValues));
  }, []);

//   useEffect(() => {
//     const searchInput = document.querySelector('.select2-search__field');
//     if (searchInput) {
//         if (selectedValuesCount > 0) {
//             // Disable the search input when selectedValuesCount is greater than 0
//             searchInput.disabled = true;
//         } else {
//             // Enable the search input otherwise
//             searchInput.disabled = false;
//         }
//     }
// }, [selectedValuesCount]);


  const storeSelectedValuesInHiddenInput = useCallback((selectedValues) => {
    if (!isLocality) {
      hiddenInputRef.current.value = JSON.stringify(selectedValues);
    }
  }, [isLocality]);

  const handleEnterKey = useCallback((e) => {
    if (e.key === 'Enter') {
      const input = $(selectHeaderRef.current).data('select2').$dropdown.find('.select2-search__field');
      if (input.val().trim() !== '') {
        const nlpQuery = input.val().trim();
        const nlpOption = { id: `nlp-${nlpQuery}`, text: nlpQuery, type: 'nlptext', query: nlpQuery };

        const newOption = new Option(nlpOption.text, nlpOption.id, true, true);
        $(selectHeaderRef.current).append(newOption).trigger('change');

        const currentSelectedValues = $(selectHeaderRef.current).select2('data');
        const updatedSelectedValues = [...currentSelectedValues, nlpOption];

        storeSelectedValues(updatedSelectedValues);
        storeSelectedValuesInHiddenInput(updatedSelectedValues);
        updateUrlParams(updatedSelectedValues, 'select');
      }
    }
  }, [storeSelectedValues, storeSelectedValuesInHiddenInput, updateUrlParams]);

  let isDropdownOpen = false;

  const handleScroll = () => {
    if (isDropdownOpen) {
      $(selectHeaderRef.current).select2('close');
      isDropdownOpen = false;
    }
  };

  useEffect(() => {
    const initSelect2 = () => {
      $(selectHeaderRef.current).select2({
        placeholder: isLocality ? 'Search for locality' : 'Search for locality, developer, project, or configuration',
        minimumInputLength: 0,
        multiple: true,
        language: {
          searching: function () {
            return undefined; // Completely removes the line
          }
        },
        ajax: {
          transport: function (params, success, failure) {
            const term = params.data.term ? params.data.term.toLowerCase() : '';
            let results = [];

            if (term.length < 3) {
              if (isLocality) {
                results = [{ text: 'Locations', children: searchDataJson.locality_by_slug.map(loc => ({ id: loc.slug, text: loc.name, type: 'locality_by_slug' })) }];
              } else {
                const filteredLocations = irfs ? searchDataJson.locations.filter(loc => loc.is_irfs) : searchDataJson.locations;
                const filteredProjects = irfs ? searchDataJson.projects.filter(proj => proj.is_irfs) : searchDataJson.projects;
                const filteredDevelopers = irfs ? searchDataJson.developers.filter(dev => dev.is_irfs) : searchDataJson.developers;

                results = [
                  { text: 'Locations', children: filteredLocations.map(loc => ({ id: loc.location, text: loc.location, type: 'locality' })) },
                  { text: 'Projects', children: filteredProjects.map(proj => ({ id: proj.id, text: proj.name, type: 'project', slug: proj.slug })) },
                  { text: 'Developers', children: filteredDevelopers.map(dev => ({ id: dev.id, text: dev.name, type: 'developer' })) },
                  { text: 'Configuration', children: searchDataJson.configurations.map(conf => ({ id: conf.slug || conf.bedrooms, text: `${conf.bedrooms} BHK`, type: 'configuration', bhk: conf.bedrooms })) }
                ];
              }
            } else {
              const filterFunction = item => item.toLowerCase().includes(term);

              if (isLocality) {
                const filteredLocationsBySlug = searchDataJson.locality_by_slug.filter(loc => (!irfs || loc.is_irfs) && loc.name.toLowerCase().includes(term));
                if (filteredLocationsBySlug.length > 0) {
                  results.push({ text: 'Locations', children: filteredLocationsBySlug.map(loc => ({ id: loc.slug, text: loc.name, type: 'locality_by_slug' })) });
                }
              } else {
                const filteredLocations = searchDataJson.locations.filter(loc => (!irfs || loc.is_irfs) && filterFunction(loc.location));
                const filteredProjects = searchDataJson.projects.filter(proj => (!irfs || proj.is_irfs) && filterFunction(proj.name));
                const filteredDevelopers = searchDataJson.developers.filter(dev => (!irfs || dev.is_irfs) && filterFunction(dev.name));
                const filteredConfigurations = searchDataJson.configurations.filter(conf => filterFunction(`${conf.bedrooms} BHK`));

                if (filteredLocations.length > 0) results.push({ text: 'Locations', children: filteredLocations.map(loc => ({ id: loc.location, text: loc.location, type: 'locality' })) });
                if (filteredProjects.length > 0) results.push({ text: 'Projects', children: filteredProjects.map(proj => ({ id: proj.id, text: proj.name, type: 'project', slug: proj.slug })) });
                if (filteredDevelopers.length > 0) results.push({ text: 'Developers', children: filteredDevelopers.map(dev => ({ id: dev.id, text: dev.name, type: 'developer' })) });
                if (filteredConfigurations.length > 0) results.push({ text: 'Configuration', children: filteredConfigurations.map(conf => ({ id: conf.slug || conf.bedrooms, text: `${conf.bedrooms} BHK`, type: 'configuration', bhk: conf.bedrooms })) });
              }
            }

            results.push({
              text: 'Search Powered by GPT',
              children: [{ id: `nlp-${term}`, text: term, type: 'nlptext', query: term }]
            });

            const selectedValues = $(selectHeaderRef.current).select2('data');
            results = results.map(group => ({
              ...group,
              children: group.children.filter(child =>
                !selectedValues.some(selected => selected.id === child.id)
              )
            })).filter(group => group.children.length > 0);

            success({ results });
          },
          delay: 250
        }
      })
        .on('select2:open', function () {
          // alert('select2:open');
          // if (selectedValuesCount >= 3){
          //   document.querySelector('.search-header').style.pointerEvents = 'none';
          // }
          // else{
          //   document.querySelector('.search-header').style.pointerEvents = 'unset';
          // }
          $(selectHeaderRef.current).data('select2').$dropdown.find('.select2-search__field').on('keydown', handleEnterKey);
          isDropdownOpen = true;
        })
        .on('select2:close', function () {
          // alert('select2:close');
          // document.querySelector('.search-header').style.pointerEvents = 'unset';
          $(selectHeaderRef.current).data('select2').$dropdown.find('.select2-search__field').off('keydown', handleEnterKey);
          isDropdownOpen = false;
        })
        .on('select2:select', function (e) {
          const selectedValues = $(selectHeaderRef.current).select2('data');
          const updatedValues = selectedValues.map((item) => ({
            ...item,
            type: item.type || getItemType(item)
          }));
          setSelectedItemsState(updatedValues);
          storeSelectedValues(updatedValues);
          storeSelectedValuesInHiddenInput(updatedValues);
          updateUrlParams(updatedValues, 'select');
          if (handleClose) {
            handleClose();
          }
        })
        .on('select2:unselect', function (e) {
          const remainingValues = selectedItemsState.filter(item => item.id !== e.params.data.id);
          //console.log('Remaining values after unselect: ', remainingValues);

          const updatedValues = remainingValues.map((item) => {
            const originalItem = findOriginalItem(item);
            return {
              ...originalItem,
              id: item.id,
              text: item.text,
              type: item.type || getItemType(item)
            };
          });

          //console.log('Updated values after unselect: ', updatedValues);
          setSelectedItemsState(updatedValues);
          storeSelectedValues(updatedValues);
          storeSelectedValuesInHiddenInput(updatedValues);
          updateUrlParams(updatedValues, 'unselect', e.params.data);

          if (handleClose) {
            handleClose();
          }
        });

    };

    initSelect2();

    // Attach scroll event to the window
    window.addEventListener('scroll', handleScroll);

    const urlParams = new URLSearchParams(location.search);
    const localityName = urlParams.get('locality_name');
    const bhk = urlParams.get('bhk');
    const nlp = urlParams.get('nlp');
    const query = searchNlpQuery;

    let initialSelectedValues = [];

    if (localityName) {
      const localities = localityName.split(',').map(locality => locality.trim());
      localities.forEach(locality => {
        const localityObj = searchDataJson.locations.find(loc => loc.location.toLowerCase() === locality.toLowerCase());
        if (localityObj && !initialSelectedValues.some(val => val.text === localityObj.location)) {
          initialSelectedValues.push({ id: localityObj.location, text: localityObj.location, type: 'locality' });
        }
      });
    }

    if (!localityName) {
      $(selectHeaderRef.current).val(null).trigger('change');
      storeSelectedValues([]);
      storeSelectedValuesInHiddenInput([]);
    }

    if (bhk) {
      const bhks = bhk.split(',').map(b => parseInt(b));
      bhks.forEach(b => {
        const bhkObj = searchDataJson.configurations.find(conf => conf.bedrooms === b);
        if (bhkObj) {
          initialSelectedValues.push({ id: bhkObj.slug || bhkObj.bedrooms, text: `${bhkObj.bedrooms} BHK`, type: 'configuration', bhk: bhkObj.bedrooms });
        }
      });
    }

    if (nlp === '1' && query) {
      initialSelectedValues.push({ id: `nlp-${query}`, text: query, type: 'nlptext' });
    }

    if (initialSelectedValues.length > 0) {
      initialSelectedValues.forEach(value => {
        if (!$(selectHeaderRef.current).find(`option[value="${value.id}"]`).length) {
          const newOption = new Option(value.text, value.id, true, true);
          $(selectHeaderRef.current).append(newOption);
        }
      });
      $(selectHeaderRef.current).trigger('change');
      storeSelectedValues(initialSelectedValues);
      storeSelectedValuesInHiddenInput(initialSelectedValues);
    }

    if (query && query !== '') {
      const currentSelectedValues = $(selectHeaderRef.current).select2('data') || [];
      const updatedValues = currentSelectedValues.filter(
        (item) => item.text !== query && item.id !== query
      );

      const updatedIds = updatedValues.map((item) => item.id);
      $(selectHeaderRef.current).val(updatedIds).trigger('change');
      storeSelectedValues(updatedValues);
      storeSelectedValuesInHiddenInput(updatedValues);
    }

    // Automatically open dropdown if autoOpen is true
    if (autoOpen && !isIOS) {
      selectHeaderRef.current?.focus(); // Focus the input field
      
    }


    return () => {
      // Cleanup on component unmount
      window.removeEventListener('scroll', handleScroll);
      if (selectHeaderRef.current) {
        $(selectHeaderRef.current).select2('destroy');
      }
    };
  }, [location, isLocality, irfs, handleClose, handleEnterKey, storeSelectedValues, storeSelectedValuesInHiddenInput, updateUrlParams, searchNlpQuery]);

  const getItemType = (item) => {
    if (searchDataJson.locations.some(loc => loc.location.toLowerCase() === item.text.toLowerCase())) return 'locality';
    if (searchDataJson.projects.some(proj => proj.name.toLowerCase() === item.text.toLowerCase())) return 'project';
    if (searchDataJson.developers.some(dev => dev.name.toLowerCase() === item.text.toLowerCase())) return 'developer';
    if (searchDataJson.configurations.some(conf => `${conf.bedrooms} BHK`.toLowerCase() === item.text.toLowerCase())) return 'configuration';
    return 'locality'; // Default to locality if no match found
  };

  const findOriginalItem = (item) => {
    return searchDataJson.locations.find(loc => loc.location.toLowerCase() === item.text.toLowerCase()) ||
      searchDataJson.projects.find(proj => proj.name.toLowerCase() === item.text.toLowerCase()) ||
      searchDataJson.developers.find(dev => dev.name.toLowerCase() === item.text.toLowerCase()) ||
      searchDataJson.configurations.find(conf => `${conf.bedrooms} BHK`.toLowerCase() === item.text.toLowerCase()) ||
      item;
  };

  useEffect(() => {
    return () => {
      if (location.pathname === '/' || location.pathname.includes('/project/') || location.pathname === '/irfs') {
        $(selectHeaderRef.current).empty();
        localStorage.removeItem('selectedValues');
      }
      const urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.toString()) {
        $(selectHeaderRef.current).empty();
        localStorage.removeItem('selectedValues');
      }
    };
  }, [location.pathname]);

  const handleSearchButtonClick = () => {
    const selectedValues = $(selectHeaderRef.current).select2('data');
    storeSelectedValuesInHiddenInput(selectedValues);
    updateUrlParams(selectedValues, 'select');
  };

  return (
    <>
      {!isLocality && (
        <select className='cityDropdown'>
          <option>Pune</option>
        </select>
      )}
      <select ref={selectHeaderRef} className="form-control search-input"></select>
      <input type="hidden" ref={hiddenInputRef} />
      <a className='searchBtn d-none d-lg-block' onClick={handleSearchButtonClick}>Search</a>
    </>
  );
};

export default SearchInput;