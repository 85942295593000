import React, { useState, useEffect, lazy, useContext } from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { executeLaravelFrontAPI, allUrlParams } from './../../Admin/Utils'; // Ensure this is the correct path or adapt as necessary
import { SearchVisibilityContext } from '../Layouts/SearchVisibilityContext';
import { Icon } from '@iconify/react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ProjectCard from '../Layouts/ProjectCard';
import ProjectCardListing from './ProjectCardListing';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { set } from 'mongoose';


const RelatedProjects = ({
    project,
    locationName,
    slidesToShow = [4, 3, 2, 1],
    isWishlistActive,
    is_irfs = false,
    isProjectListing = "false",
    selectedTab,
    handleTabClick,
    getMinMaxCarpet,
    appAbsPath,
    userdetails,
    loggeduserdetails,
    locality_name,
    totalListProjCount = 0,
    currentListedProjects = [],
}) => {
    const [relatedProjects, setRelatedProjects] = useState([]);
    const navigate = useNavigate();
    const [hasMore, setHasMore] = useState(true); // To control the Infinite Scroll
    const [page, setPage] = useState(1); // Current page for fetching data
    const scrollContainerRef = React.createRef();
    const [isloading, setIsLoading] = useState(true);
    const [nearByLocation, setNearByLocation] = useState([]);
    const [radiusLoc, setRadius] = useState(0);
    const loc = locationName;
    const {setFooterVisibility } = useContext(SearchVisibilityContext);

    // console.log("isProjectListing: ", isProjectListing)

    useEffect(() => {
        if (isProjectListing == 'true'){
            if (hasMore){
                setFooterVisibility(false)
            }
            else{
                setFooterVisibility(true)
            }
        }
    }, [hasMore]);

    useEffect(() => {
        if (locationName !== null && locationName !== undefined && locationName !== '' && !Array.isArray(locationName)) {
            // fetchRelatedProjects(locationName); // Initial fetch

            if (isProjectListing == "true") {
                setRadius(5)
                fetchRelatedProjects(locationName);
            } else {
                setRadius(prevRadius => prevRadius + 2);
                fetchNearBylocation(loc);
            }
            setIsLoading(false);
        } else {
            fetchRelatedProjects(null); // Initial fetch
            setIsLoading(false);
        }
    }, [page, locationName, currentListedProjects]);

    const fetchNearBylocation = async (locationName) => {
        try {
            // console.log("~~~~~~~~~~~~~~~~locationName: ", locationName);
            const response = await axios.get('https://nominatim.openstreetmap.org/search', {

                params: {
                    q: `${isProjectListing == "true" ? locationName : loc}, Pune, Maharashtra, India`,
                    format: 'json',
                },
            });
            // console.log('response: ', response.data[0]);
            // console.log("nominatim: " . response);

            const latitude = parseFloat(response.data[0]?.lat);
            const longitude = parseFloat(response.data[0]?.lon);
            let paramsData = {
                latitude,
                longitude,
                skiplocation: isProjectListing == "true" ? locationName : loc,
                radius: radiusLoc,
            };

            const locationresult = await executeLaravelFrontAPI('nearbylocation', paramsData, 'GET');
            const nearbylocation = locationresult.data;


            if ((!nearbylocation || Object.keys(nearbylocation).length <= 4) && isProjectListing == 'false') {
                setRadius(prevRadius => prevRadius + 2);
            } else {
                setNearByLocation(Object.values(nearbylocation).join(','));
            }
            if (((nearbylocation || Object.keys(nearbylocation).length >= 4)) && isProjectListing == 'false') {
                fetchRelatedProjects(Object.values(nearbylocation).join(','));
            }


        } catch (error) {
            if (axios.isAxiosError(error)) {
                console.error('Axios error:', error.message);
                if (error.response) {
                    console.error('Server responded with status:', error.response.status);
                } else if (error.request) {
                    console.error('No response received:', error.request);
                } else {
                    console.error('Error setting up request:', error.message);
                }
            } else {
                console.error('Non-Axios error:', error.message);
            }
            // Handling case when location data cannot be fetched
            setNearByLocation('');
        }
    }

    // UseEffect to log radiusLoc changes
    useEffect(() => {
        if (radiusLoc > 0) {
            // console.log("Updated radiusLoc: ", radiusLoc); // Now this will reflect the updated value
            fetchNearBylocation(loc);
        }
    }, [radiusLoc]); // This effect runs every time radiusLoc updates



    const fetchRelatedProjects = async (locationName) => {
        try {

            let paramsData = {
                per_page: 10,
                page: page, // Fetch data by page
            };
            paramsData = { ...paramsData, ...allUrlParams(['per_page', 'page']) };

            if (locationName !== null) {
                paramsData = {
                    locality_name: locationName,
                    is_irfs: is_irfs,
                    searchtype: 'locality_name',
                    per_page: 5,
                    page: page, // Fetch data by page
                };
                paramsData = { ...paramsData, ...allUrlParams(['locality_name', 'is_irfs', 'searchtype', 'per_page', 'page']) };
            } else {
                // price range
                // Adjust price range
                if (paramsData.maxprice || paramsData.minprice) {
                    if (paramsData.maxprice){paramsData.maxprice = parseInt(paramsData.maxprice || 0) + 1000000;}
                    if (paramsData.minprice){paramsData.minprice = parseInt(paramsData.minprice || 0) - 1000000;}

                    if (paramsData.maxprice && paramsData.maxprice <= 0) paramsData.maxprice = 500000;
                    if (paramsData.minprice && paramsData.minprice <= 0) paramsData.minprice = 500000;
                }

                // Adjust carpet area range
                if (paramsData.carpet_max || paramsData.carpet_min) {
                    if (paramsData.carpet_max){paramsData.carpet_max = parseInt(paramsData.carpet_max || 0) + 100;}
                    if (paramsData.carpet_min){paramsData.carpet_min = parseInt(paramsData.carpet_min || 0) - 100;}

                    if (paramsData.carpet_max && paramsData.carpet_max <= 0) paramsData.carpet_max = 100;
                    if (paramsData.carpet_min && paramsData.carpet_min <= 0) paramsData.carpet_min = 100;
                }

                // Adjust bedroom range
                if (paramsData.bhk) {
                    // Split into an array, process each value, and join back
                    paramsData.bhk = paramsData.bhk
                        .split(',')
                        .map((value) => {
                            const bhkValue = parseFloat(value.trim());
                            return `${bhkValue - 0.5}, ${bhkValue + 0.5}, ${bhkValue - 1}, ${bhkValue + 1}`;
                        })
                        .join(', '); // Join processed ranges into a single string
                }
                if (paramsData.configuration) {
                    // Split into an array, process each value, and join back
                    paramsData.configuration = paramsData.configuration
                        .split(',')
                        .map((value) => {
                            const configValue = parseFloat(value.trim()); // Parse to float
                            return `${configValue - 0.5} BHK, ${configValue + 0.5} BHK, ${configValue - 1} BHK, ${configValue + 1} BHK`;
                        })
                        .join(', '); // Join processed ranges into a single string
                }

                // Adjust type for multiple values
                if (paramsData.configuration && paramsData.bhk && paramsData.type) {
                    const bhkCount = paramsData.bhk.split(',').length; // Count total entries in bhk
                    paramsData.type = Array(bhkCount).fill('bhk').join(', '); // Create a matching 'type' string
                }

                // Adjust project status
                if (paramsData.project_status) {
                    const allStatuses = [
                        "Ready To Move",
                        "New Launch",
                        "New Project",
                        "Under Construction",
                        "Nearing Possession"
                    ];
    
                    // If the selected project status exists in the list, exclude it
                    paramsData.project_status = allStatuses.filter(status => status !== paramsData.project_status).join(', ');
                }

                // Adjust possession range
                if(paramsData.possession_months){
                    if(paramsData.possession_months=='3' || paramsData.possession_months=='6'){
                        paramsData.possession_months = '12';
                    }else if(paramsData.possession_months=='1'){
                        paramsData.possession_months = '24';
                    }else if(paramsData.possession_months=='24'){
                        paramsData.possession_months = '36';
                    }else if(paramsData.possession_months=='36'){
                        paramsData.possession_months = '60';
                    }else if(paramsData.possession_months=='84'){
                        paramsData.possession_months = '60';
                    }
                }

            }


            const result = await executeLaravelFrontAPI('projects', paramsData, 'GET');
            setRelatedProjects(prevProjects => {
                // Create a Set of existing project IDs for quick lookup
                const existingIds = new Set(prevProjects.map(p => p.id));
                
                // Create a Set of currentListedProjects IDs, with validation
                const currentListedIds = new Set(
                    (Array.isArray(currentListedProjects) && currentListedProjects.length > 0)
                    ? currentListedProjects.map(p => p.id)
                    : []
                );

                // Filter out new projects that already exist in the state or in currentListedProjects
                const newUniqueProjects = result.data.data.filter(project => 
                    !existingIds.has(project.id) && !currentListedIds.has(project.id)
                );

                // Return the new array combining old projects and filtered new projects
                return [...prevProjects, ...newUniqueProjects];
            });

            // console.log("RelatedProjects ",relatedProjects);
            // console.log("result.data.data ",result.data.data);

            if (result.data.data.length <= 4 && isProjectListing == 'false') {
                setRadius(prevRadius => prevRadius + 2);
                // console.log("radiusLoc: ",radiusLoc)             
                // fetchNearBylocation(loc);
            }


            setHasMore(result.data.data.length > 0); // Update if more data is available

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const loadMoreProjects = () => {
        if (isScrolledNearBottom(scrollContainerRef.current)) {
            setPage(prevPage => prevPage + 1);
        }
    };

    const isScrolledNearBottom = (element) => {
        if (!element) return false;
        const threshold = 100; // Pixels from the bottom of the element to trigger loading more items
        const position = element.scrollHeight - element.scrollTop - element.clientHeight;
        return position < threshold;
    };

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', loadMoreProjects);
        }
        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', loadMoreProjects);
            }
        };
    }, [scrollContainerRef.current]); // Ensure the listener is updated if the ref changes


    useEffect(() => {
        console.log('relatedProjects: ', relatedProjects);
    }, [relatedProjects]);


    // useEffect(() => {
    //     const fetchRelatedProjects = async () => {
    //         try {
    //             const paramsData = {
    //                 locality_name: locationName ?? '',
    //                 is_irfs: is_irfs,
    //                 searchtype: 'locality_name',
    //                 per_page: 15,
    //             };
    //             const result = await executeLaravelFrontAPI('projects', paramsData, 'GET');
    //             setRelatedProjects(result.data.data);
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         }
    //     };

    //     fetchRelatedProjects();
    // }, [locationName]);

    // console.log('isProjectListing ', isProjectListing);

    const handlerelatedProj = (projectSlug) => {
        window.scrollTo(0, 0);
        navigate(`/project/${projectSlug}`);
    };

    const [slidesToShowDesk, slidesToShowLarge, slidesToShowIpro, slidesToShowIpad, slidesToShowMob] = slidesToShow;
    const settingsForRelatedProject = {
        dots: false,
        arrows: true,
        infinite: relatedProjects.length > slidesToShowDesk ? true : false,
        // autoplay: true,
        speed: 800,
        slidesToShow: relatedProjects.length > slidesToShowDesk ? slidesToShowDesk : relatedProjects.length,
        slidesToScroll: 1,
        centerMode: false,
        variableWidth: true,
        prevArrow: <Icon icon="ri:arrow-left-s-line" color="#000" className="prev-arrow" />,
        nextArrow: <Icon icon="ri:arrow-right-s-line" color="#000" className="next-arrow" />,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: slidesToShowLarge || 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: slidesToShowIpro || 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 993,
                settings: {
                    slidesToShow: slidesToShowIpad || 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 641,
                settings: {
                    slidesToShow: slidesToShowMob || 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                },
            },
        ],
    };

    return (

        isloading ? <div className="custom-loader text-center m-auto"></div> :
            <>
                {
                    totalListProjCount != 0 && (
                        <div className="noProjectWrapper mt-4 mb-4">
                            <p className="text-center" style={{ textWrap: 'balance' }}>
                                <Icon
                                    icon="fa6-regular:face-sad-tear"
                                    className="me-1 mb-1 align-middle"
                                    color="#667486"
                                />
                                You've reached the end of the list.
                                <br />
                                Explore nearby projects or refine your search for more options.
                            </p>
                        </div>
                    )
                }
                <section ref={scrollContainerRef} className={`similer-project ${isProjectListing == 'true' ? 'p-0' : ''} `}>
                    {relatedProjects.length > 0 &&(
                        <h2 className="fw-bold mb-3 mt-2">Explore Near By Projects</h2>
                    )}
                    {isProjectListing == 'true' && relatedProjects.length > 0 ?
                        <InfiniteScroll
                            dataLength={relatedProjects.length}
                            next={loadMoreProjects}
                            hasMore={hasMore}
                        //     loader={<h4 className='loaderWrapper'><span className="custom-loader m-auto"></span></h4>}
                        // endMessage={relatedProjects}
                        >
                            {relatedProjects
                                .filter((relatedProject) => {
                                    // Only filter by project name if the project prop is provided
                                    if (project) {
                                        return relatedProject.name !== project.name;
                                    }
                                    return true;  // If no project is passed, include all related projects
                                })
                                .map((filteredProject, index) => (
                                    <ProjectCardListing
                                        key={index}
                                        project={filteredProject}
                                        selectedTab={selectedTab}
                                        handleTabClick={handleTabClick}
                                        getMinMaxCarpet={getMinMaxCarpet}
                                        appAbsPath={appAbsPath}
                                        userdetails={userdetails}
                                        isWishlistActive={isWishlistActive}
                                        loggeduserdetails={loggeduserdetails}
                                        locality_name={locationName}
                                        isRecommended={true}
                                    />

                                ))}
                        </InfiniteScroll>
                        :
                        isProjectListing != 'true' && (
                            <Slider {...settingsForRelatedProject} className="top-projects-slider">
                                {relatedProjects
                                    .filter((relatedProject) => {
                                        // Only filter by project name if the project prop is provided
                                        if (project) {
                                            return relatedProject.name !== project.name;
                                        }
                                        return true;  // If no project is passed, include all related projects
                                    })
                                    .map((filteredProject, index) => (
                                        <ProjectCard key={filteredProject.id} project={filteredProject} isWishlistActive={isWishlistActive} showWishlist="yes" />
                                    ))}
                            </Slider>

                        )

                    }

                </section>
            </>

    );
};

export default RelatedProjects;

