
import React, { useState,createContext,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { use } from 'react';

export const SearchVisibilityContext = createContext();

export const SearchVisibilityProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [downloadCouponBtn, setDownloadCouponBtn] = useState(false);
  const [couponData, setCouponData] = useState({});
  const [searchOptions, setSearchOptions] = useState([]);
  const [irfsProject, setIrfsProject] = useState(false);
  const [priceFormSubmitted, setPriceFormSubmitted] = useState(false);
  const [isCouponGenerated,setIsCouponGenerated] = useState(false);
  const [showGamification, setShowGamification] = useState(true);
  const [showExitIntentPopup, setShowExitIntentPopup] = useState(true);
  const [isDetailsPage, setIsDetailsPage] = useState(false);
  const [isBwStudioPage, setisBwStudioPage] = useState(false);
  const [universalLoader, setUniversalLoader] = useState(false);
  const [footerVisibility, setFooterVisibility] = useState(true);
  const [selectedValuesCount, setSelectedValuesCount] = useState(0);
  const location = useLocation();

  useEffect(() => {
    // console.log(location.pathname);
  
    const pathsToHideFooter = [
      'projects',
      '/ready-to-move-flats-in-pune',
      '/great-view-projects-in-pune',
      '/new-projects-in-pune',
      '/hot-selling-projects-in-pune',
      '/affordable-apartments-in-pune',
      '/high-rise-projects-in-pune',
    ];
  
    const shouldHideFooter = pathsToHideFooter.some(path => 
      location.pathname.includes(path)
    );
  
    setFooterVisibility(!shouldHideFooter);
  }, [location]);

  return (
    <SearchVisibilityContext.Provider
      value={{
        isVisible,
        setIsVisible,
        searchOptions,
        setSearchOptions,
        downloadCouponBtn,
        setDownloadCouponBtn,
        couponData,
        setCouponData,
        irfsProject,
        setIrfsProject,
        priceFormSubmitted,
        setPriceFormSubmitted,
        isCouponGenerated,
        setIsCouponGenerated,
        showGamification,
        setShowGamification,
        showExitIntentPopup,
        setShowExitIntentPopup,
        isDetailsPage,
        setIsDetailsPage,
        isBwStudioPage,
        setisBwStudioPage,
        universalLoader,
        setUniversalLoader,
        footerVisibility,
        setFooterVisibility,
        selectedValuesCount,
        setSelectedValuesCount
      }}
    >
      {children}
    </SearchVisibilityContext.Provider>
  );
};
