import React from 'react';

const GenerateFilterSentence = ({ totalItems }) => {
    const generateSentence = () => {
        const params = getQueryParams();

        // Extract and format parameters
        const locality = params.locality_name
            ? Array.isArray(params.locality_name)
                ? params.locality_name.join(', ') // Handle as an array
                : params.locality_name.replace(/,/g, ', ') // Handle as a string
            : null;

        const bhk = params.bhk
            ? Array.isArray(params.bhk)
                ? params.bhk.map(b => `${b} BHK`).join(', ') // Handle as an array
                : `${params.bhk.replace(/,/g, ', ')} BHK` // Handle as a string
            : null;
        const projectStatus = params.project_status
            ? decodeURIComponent(params.project_status).replace(/\+/g, ' ').replace(/%2B/g, ' ')
            : null;


        const possession =
            params.possession_months !== undefined
                ? `possession in ${convertMonthsToYears(Number(params.possession_months))}`
                : null;
        const minPrice = params.minprice ? formatPrice(params.minprice) : null;
        const maxPrice = params.maxprice ? formatPrice(params.maxprice) : null;

        // Determine price range sentence
        let priceRange = null;
        if (minPrice && maxPrice) {
            priceRange = `between ${minPrice} and ${maxPrice}`;
        } else if (minPrice) {
            priceRange = `above ${minPrice}`;
        } else if (maxPrice) {
            priceRange = `below ${maxPrice}`;
        }

        const carpetArea =
            params.carpet_min && params.carpet_max
                ? `with carpet area from ${params.carpet_min} to ${params.carpet_max} sq. ft.`
                : null;

        // Build sentence dynamically
        const filters = [];

        if (bhk) filters.push(bhk);
        if (locality) filters.push(`in ${locality}`);
        if (priceRange) filters.push(priceRange);
        if (projectStatus) filters.push(projectStatus);
        if (possession) filters.push(possession);
        if (carpetArea) filters.push(carpetArea);

        // Construct the sentence
        if (filters.length > 0) {
            return `| ${filters.join(' ')}`; // Ensure space after commas between filters
        }

        // Default fallback if no filters are applied
        return '';
    };


    // Helper function to format prices
    const formatPrice = (price) => {
        const num = parseInt(price, 10);
        if (num >= 10000000) {
            return `${(num / 10000000).toFixed(1)} Cr`;
        } else if (num >= 100000) {
            return `${(num / 100000).toFixed(1)} Lakhs`;
        }
        return `${num}`;
    };

    // Helper function to convert months to years
    const convertMonthsToYears = (months) => {
        if (months >= 12) {
            return `${(months / 12).toFixed(1)} years`;
        }
        return `${months} months`;
    };

    // Function to parse query parameters
    const getQueryParams = () => {
        const searchParams = new URLSearchParams(window.location.search);
        const params = {};
        for (let [key, value] of searchParams.entries()) {
            if (key in params) {
                params[key] = [...params[key], value];
            } else {
                params[key] = value.includes(',') ? value.split(',') : value;
            }
        }
        return params;
    };

    const sentence = generateSentence();

    return (
        <>
            {totalItems > 0 ? (
                <p className='fw-bold mb-0'>
                    <span className="text-primary text-orange century-font">{totalItems} {totalItems === 1 ? 'Property' : 'Properties'}</span> <span className=''>{sentence.replace(/^| /, '')}</span>
                </p>
            ) : (
                <>
                    <p className='fw-bold mb-0'>
                        <span className="text-primary text-orange century-font">0 Properties</span>
                        <span>{sentence}</span>
                    </p>
                </>
            )}
        </>
    );
};

export default GenerateFilterSentence;
